import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar/Navbar';
import ScrollTop from '../components/ScrollTop/ScrollTop';
import Footer from '../sections/Footer/Footer';
import HeroText from '../sections/HeroText/HeroText';
import CaseStudy from '../sections/CaseStudy/CaseStudy';
import Button from '../components/Button/Button';
import entryAnimation from '../resources/entry-animation';

// markup
const IndexPage = () => {
    const navbarBreakpointRef = useRef();
    const data = useStaticQuery(query);
    const [caseStudies, setCaseStudies] = useState(data.allPosts.nodes);
    const mainRef = useRef(null);
    entryAnimation(mainRef, true);
    const filterPosts = (category) => {
        setCaseStudies(
            category === 'all'
                ? data.allPosts.nodes
                : data.allPosts.nodes.filter((post) => post.category === category),
        );
    };

    return (
        <main ref={mainRef}>
            <div ref={navbarBreakpointRef} className="ref-navbar" />
            <Navbar
                breakpointRef={navbarBreakpointRef}
            />
            <Helmet>
                <title>Wiedza | GetHero</title>
            </Helmet>
            <HeroText
                header={`#${data.allKnowledgePage.nodes[0].header}`}
                firstParagraph={data.allKnowledgePage.nodes[0].first_hero_paragraph}
                secondParagraph={data.allKnowledgePage.nodes[0].second_hero_paragraph}
            />
            <div className="knowledge-categories">
                <div className="knowledge-categories__wrapper">
                    <Button click={() => filterPosts('all')} additionalClasses="btn--round">Wszystkie</Button>
                    <Button click={() => filterPosts('Case study')} additionalClasses="btn--round">Case study</Button>
                    <Button click={() => filterPosts('Media o nas')} additionalClasses="btn--round">Media o nas</Button>
                    <Button click={() => filterPosts('Blog')} additionalClasses="btn--round">Blog</Button>
                </div>
            </div>
            <div>
                <CaseStudy
                    additionalClasses="case-study-section__knowledge"
                    caseStudies={caseStudies}
                    showSectionName={false}
                    showCategories
                />
            </div>
            <Footer />
            <ScrollTop />
        </main>
    );
};

const query = graphql`
    query KnowledgeSettings {
      allKnowledgePage {
        nodes {
          header
          first_hero_paragraph
          second_hero_paragraph
        }
      }
      allPosts(sort: {fields: flotiqInternal___createdAt, order: DESC}) {
        nodes {
          id
          slug
          category
          name
          description
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }`;

export default IndexPage;
